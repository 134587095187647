<template>
  <div class="container-fluid">
    <div class="row no-gutters shadow">
      <div class="col-lg-6 img-container">
        <img src="../assets/asset-1.png" class="img-fluid" />
      </div>

      <div class="col-lg-6 secondCol">
        <h1 class="title">Welcome! {{ currentUser.email }}</h1>
        <form @submit.prevent="handleSendEmail" class="form shadow">
          <p class="form-title">Edit profile</p>
          <label for="companyName">Subscription valid until</label>
          <div class="input-group mb-3">
            <input
              type="text"
              v-model="subscriptionDate"
              class="form-control"
              id="subscriptionDate"
              placeholder="Subscription validity"
              disabled
            />
          </div>

          <label for="companyName">Company name</label>
          <div class="input-group mb-3">
            <input
              type="text"
              v-model="companyName"
              class="form-control"
              id="companyName"
              placeholder="Company name"
            />
          </div>
          <label for="city">City</label>
          <div class="input-group mb-3">
            <input
              id="city"
              v-model="city"
              type="text"
              class="form-control"
              name="city"
              placeholder="Enter city"
            />
          </div>

          <label for="postalCode">Postal code</label>
          <div class="input-group mb-3">
            <input
              id="postalCode"
              v-model="postalCode"
              type="text"
              class="form-control"
              name="postalCode"
              placeholder="Enter postal code"
            />
          </div>
          <label for="street">Street</label>
          <div class="input-group mb-3">
            <input
              id="street"
              v-model="street"
              type="text"
              class="form-control"
              name="street"
              placeholder="Enter street"
            />
          </div>
          <label for="nip">Tax number</label>
          <div class="input-group mb-3">
            <input
              id="nip"
              v-model="nip"
              type="text"
              class="form-control"
              name="nip"
              placeholder="Enter tax number"
            />
          </div>
          <div class="text-right">
            <button
              type="submit"
              class="mt-4 btn btn-yellow"
              @click="handleUpdateUser()"
            >
              <span class="btn-yellow-inner" style="font-weight:bold;"
                >edit</span
              >
            </button>
          </div>
          <div v-if="message == 200" class="alert alert-success" role="alert">
            Edited succesfully
          </div>
          <div v-else-if="message" class="alert alert-danger" role="alert">
            {{ message.message }}
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import shared from "../shared";
import UserService from "../services/user.service";

export default {
  name: "Profile",
  data() {
    return {
      companyName: "",
      city: "",
      postalCode: "",
      street: "",
      nip: "",
      message: "",
      subscriptionDate: ""
    };
  },
  created() {
    this.$store.dispatch("user/getUsersSubscriptionExpiryDate").then(() => {
      this.mapSubscription();
    });
    this.$store.dispatch("user/getUser").then(() => {
      this.mapVuexToData();
    });
    this.formattedDate = shared.formattedDate;
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    user() {
      return this.$store.state.user.user;
    },
    subscription() {
      return this.$store.state.user.subscriptionExpiryDate;
    }
  },
  methods: {
    mapSubscription() {
      this.subscriptionDate = this.formattedDate(
        this.subscription.data.expiryDate
      );
    },
    mapVuexToData() {
      this.companyName = this.user.data.companyName;
      this.city = this.user.data.address.city;
      this.postalCode = this.user.data.address.postalCode;
      this.street = this.user.data.address.street;
      this.nip = this.user.data.nip;
    },
    handleUpdateUser() {
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          return;
        }
        const { companyName, city, postalCode, street, nip, message } = this;
        UserService.updateUser({
          companyName,
          city,
          postalCode,
          street,
          nip,
          message
        }).then(
          response => {
            this.message = response.status;
            setTimeout(() => (this.message = ""), 2000);
          },
          error => {
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
            setTimeout(() => (this.message = ""), 2000);
          }
        );
      });
    }
  }
};
</script>

<style scoped>
@import "../assets/css/main.css";
.title {
  margin: 60px 0;
  font-size: 30px;
  text-align: center;
}
.container-fluid {
  padding: 0;
}
label {
  font-size: 0.75rem;
  font-weight: bold;
}
</style>
